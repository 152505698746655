import React from "react"
import Select from "react-select"
import CreatableSelect from 'react-select/creatable'
import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate'
const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect)

class Renderer {
  constructor(controller) {
    this.controller = controller
  }

  defaultProps() {
    return {
      'className': 'react-select-container',
      'classNamePrefix': 'react-select',
      'options': this.controller.options,
      'defaultValue': this.controller.selectedOptions,
      'onChange': this.controller.updateSelect.bind(this.controller),
      'isMulti': this.controller.isMulti,
      'isClearable': this.controller.clearValue,
      'placeholder': this.controller.placeholder,
      'loadingMessage': () => 'Szukam...',
      'noOptionsMessage': () => 'Brak opcji',
      'createOptionPosition': 'first',
      'formatCreateLabel': (inputValue) => `Dodaj '${inputValue}'`
    }
  }
}

export class ReactSelectRenderer extends Renderer {
  render() {
    return React.createElement(Select, { ...this.defaultProps() } , null)
  }
}

export class ReactSelectCreatableRenderer extends Renderer {
  render() {
    return React.createElement(CreatableSelect, { ...this.defaultProps() } , null)
  }
}

export class ReactSelectAsyncRenderer extends Renderer {
  props() {
    return {
      ...this.defaultProps(),
      "loadOptions": this.controller.loadOptions.bind(this.controller),
      "options": this.controller.selectedOptions
    }
  }
  render() {
    return React.createElement(AsyncPaginate, { ...this.props() } , null)
  }
}

export class ReactSelectCreatableAsyncRenderer extends Renderer {
  props() {
    return {
      ...this.defaultProps(),
      "loadOptions": this.controller.loadOptions.bind(this.controller),
      "options": this.controller.selectedOptions
    }
  }
  render() {
    return React.createElement(CreatableAsyncPaginate, { ...this.props() } , null)
  }
}
